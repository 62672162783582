import { useNuxtApp } from "#app";
import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";

import type { InternalApi } from "nitropack";
import type { AddDataBlockPayload } from "~/server/utils/dataBlockUpsert";

export const useDataBlockService = () => {
  const { $api } = useNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  // DATA BLOCKS
  const listDataBlocks = async ({
    page,
    pageSize,
    sortBy,
    sortOrder,
    localOrShared,
    search,
  }: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortOrder?: string;
    localOrShared: string;
    search?: string;
  }) => {
    return await $api<InternalApi["/api/hcd/data-blocks/list"]["get"]>(
      `/api/hcd/data-blocks/list`,
      {
        query: {
          page,
          size: pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
          localOrShared,
          search,
        },
      }
    );
  };

  const getDataBlock = async (id: string) => {
    return await $api<InternalApi["/api/hcd/data-blocks/:id"]["get"]>(
      `/api/hcd/data-blocks/${id}`,
      {
        method: "GET",
      }
    );
  };

  const useGetDataBlockQuery = (id: string, options?: MyQueryOptions) => {
    return createQuery(
      [QUERY_KEYS.DataBlocks.get, id],
      () => getDataBlock(id),
      options
    );
  };

  const addDataBlock = async (body: AddDataBlockPayload) => {
    return await $api<InternalApi["/api/hcd/data-blocks"]["post"]>(
      `/api/hcd/data-blocks`,
      {
        method: "POST",
        body: body,
      }
    );
  };

  const useAddDataBlockMutation = () =>
    createMutation((body: AddDataBlockPayload) => addDataBlock(body), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.DataBlocks.list],
        });
        notifySuccess("Success", "Data Block added");
      },
      onError: (e) => notifyError(e),
    });

  const updateDataBlock = async (id: string, body: AddDataBlockPayload) => {
    return await $api<InternalApi["/api/hcd/data-blocks/:id"]["put"]>(
      `/api/hcd/data-blocks/${id}`,
      {
        method: "PUT",
        body: body,
      }
    );
  };

  const useUpdateDataBlockMutation = () =>
    createMutation(
      ({ id, body }: { id: string; body: AddDataBlockPayload }) =>
        updateDataBlock(id, body),
      {
        onSuccess: (_x, { id }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.DataBlocks.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.DataBlocks.get, id],
          });
          notifySuccess("Success", "Data Block updated");
        },
        onError: (e) => notifyError(e),
      }
    );

  const deleteDataBlock = async (id: string) => {
    return await $api<InternalApi["/api/hcd/data-blocks/:id"]["delete"]>(
      `/api/hcd/data-blocks/${id}`,
      {
        method: "DELETE",
      }
    );
  };

  const useDeleteDataBlockMutation = () =>
    createMutation((id: string) => deleteDataBlock(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.DataBlocks.list],
        });
        notifySuccess("Success", "Data Block deleted");
      },
      onError: (e) => notifyError(e),
    });

  return {
    listDataBlocks,
    useAddDataBlockMutation,
    useGetDataBlockQuery,
    useUpdateDataBlockMutation,
    useDeleteDataBlockMutation,
  };
};
